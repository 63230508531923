import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (to && to.hash) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        el: to.hash,
                        top: 80,
                        left: 0,
                        behavior: 'smooth'
                    })
                }, 1500)
            })
        } else {
            return { top: 0, left: 0, behavior: 'smooth' }
        }
    }
}
