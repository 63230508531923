import { default as regionslssYlpY4frMeta } from "/usr/app/pages/[country-slug]/regions.vue?macro=true";
import { default as index6mGVymazUWMeta } from "/usr/app/pages/association/index.vue?macro=true";
import { default as _91vacancy_45slug_93AYOFMm8sTKMeta } from "/usr/app/pages/career/[vacancy-slug].vue?macro=true";
import { default as administrativeu0mp6sEdRaMeta } from "/usr/app/pages/career/administrative.vue?macro=true";
import { default as indexjGhBaJTQSWMeta } from "/usr/app/pages/career/index.vue?macro=true";
import { default as young1kPInvhIsjMeta } from "/usr/app/pages/career/young.vue?macro=true";
import { default as collaboration7ck9Buq3X3Meta } from "/usr/app/pages/collaboration.vue?macro=true";
import { default as contacts1ADgdaWO1AMeta } from "/usr/app/pages/contacts.vue?macro=true";
import { default as cookiespT80wZvUI0Meta } from "/usr/app/pages/cookies.vue?macro=true";
import { default as disclaimerP3EUrMXKvgMeta } from "/usr/app/pages/disclaimer.vue?macro=true";
import { default as _91slug_93H7w7jcID4FMeta } from "/usr/app/pages/employees/[slug].vue?macro=true";
import { default as index7cY0PKpVe3Meta } from "/usr/app/pages/employees/index.vue?macro=true";
import { default as _91eventSlug_93a8olTFVlozMeta } from "/usr/app/pages/events/[eventSlug].vue?macro=true";
import { default as indexfzSMqasWUtMeta } from "/usr/app/pages/index.vue?macro=true";
import { default as _91slug_93ZPEGLu0igIMeta } from "/usr/app/pages/industries/[slug].vue?macro=true";
import { default as index7vmr8VDS66Meta } from "/usr/app/pages/industries/index.vue?macro=true";
import { default as indexomVB49jzMHMeta } from "/usr/app/pages/insights/index.vue?macro=true";
import { default as _91newsSlug_93tVOwVxHbBhMeta } from "/usr/app/pages/news/[newsSlug].vue?macro=true";
import { default as _91newsletterSlug_93RA1BVhlwL0Meta } from "/usr/app/pages/newsletters/[newsletterSlug].vue?macro=true";
import { default as doing_45businessPqpeeOcZbvMeta } from "/usr/app/pages/page/doing-business.vue?macro=true";
import { default as privacy_45policyTMnQ9cnLNrMeta } from "/usr/app/pages/privacy-policy.vue?macro=true";
import { default as pro_45bonoGr6yo8z89GMeta } from "/usr/app/pages/pro-bono.vue?macro=true";
import { default as _91publicationSlug_93qMMZfazwGvMeta } from "/usr/app/pages/publications/[publicationSlug].vue?macro=true";
import { default as recognitionnsYeaVLeOhMeta } from "/usr/app/pages/recognition.vue?macro=true";
import { default as index2AvZ8pWHkwMeta } from "/usr/app/pages/regions/index.vue?macro=true";
import { default as sitemapZ9MJNeKVKxMeta } from "/usr/app/pages/sitemap.vue?macro=true";
import { default as _91slug_939tdDNlJ3bdMeta } from "/usr/app/pages/specializations/[slug].vue?macro=true";
import { default as indexPHCZ5AYvFAMeta } from "/usr/app/pages/specializations/index.vue?macro=true";
import { default as component_45stubElTBndi2gDMeta } from "/usr/app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubElTBndi2gD } from "/usr/app/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___en",
    path: regionslssYlpY4frMeta?.path ?? "/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___ar",
    path: regionslssYlpY4frMeta?.path ?? "/ar/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___mn",
    path: regionslssYlpY4frMeta?.path ?? "/mn/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___ru",
    path: regionslssYlpY4frMeta?.path ?? "/ru/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___kk",
    path: regionslssYlpY4frMeta?.path ?? "/kk/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___zh",
    path: regionslssYlpY4frMeta?.path ?? "/zh/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___tr",
    path: regionslssYlpY4frMeta?.path ?? "/tr/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionslssYlpY4frMeta?.name ?? "countryslug-regions___hy",
    path: regionslssYlpY4frMeta?.path ?? "/hy/:countryslug()/regions",
    meta: regionslssYlpY4frMeta || {},
    alias: regionslssYlpY4frMeta?.alias || [],
    redirect: regionslssYlpY4frMeta?.redirect,
    component: () => import("/usr/app/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___en",
    path: index6mGVymazUWMeta?.path ?? "/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___ar",
    path: index6mGVymazUWMeta?.path ?? "/ar/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___mn",
    path: index6mGVymazUWMeta?.path ?? "/mn/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___ru",
    path: index6mGVymazUWMeta?.path ?? "/ru/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___kk",
    path: index6mGVymazUWMeta?.path ?? "/kk/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___zh",
    path: index6mGVymazUWMeta?.path ?? "/zh/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___tr",
    path: index6mGVymazUWMeta?.path ?? "/tr/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: index6mGVymazUWMeta?.name ?? "association___hy",
    path: index6mGVymazUWMeta?.path ?? "/hy/association",
    meta: index6mGVymazUWMeta || {},
    alias: index6mGVymazUWMeta?.alias || [],
    redirect: index6mGVymazUWMeta?.redirect,
    component: () => import("/usr/app/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___en",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___ar",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/ar/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___mn",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/mn/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___ru",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/ru/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___kk",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/kk/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___zh",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/zh/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___tr",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/tr/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93AYOFMm8sTKMeta?.name ?? "career-vacancyslug___hy",
    path: _91vacancy_45slug_93AYOFMm8sTKMeta?.path ?? "/hy/career/:vacancyslug()",
    meta: _91vacancy_45slug_93AYOFMm8sTKMeta || {},
    alias: _91vacancy_45slug_93AYOFMm8sTKMeta?.alias || [],
    redirect: _91vacancy_45slug_93AYOFMm8sTKMeta?.redirect,
    component: () => import("/usr/app/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___en",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___ar",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/ar/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___mn",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/mn/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___ru",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/ru/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___kk",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/kk/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___zh",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/zh/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___tr",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/tr/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeu0mp6sEdRaMeta?.name ?? "career-administrative___hy",
    path: administrativeu0mp6sEdRaMeta?.path ?? "/hy/career/administrative",
    meta: administrativeu0mp6sEdRaMeta || {},
    alias: administrativeu0mp6sEdRaMeta?.alias || [],
    redirect: administrativeu0mp6sEdRaMeta?.redirect,
    component: () => import("/usr/app/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___en",
    path: indexjGhBaJTQSWMeta?.path ?? "/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___ar",
    path: indexjGhBaJTQSWMeta?.path ?? "/ar/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___mn",
    path: indexjGhBaJTQSWMeta?.path ?? "/mn/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___ru",
    path: indexjGhBaJTQSWMeta?.path ?? "/ru/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___kk",
    path: indexjGhBaJTQSWMeta?.path ?? "/kk/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___zh",
    path: indexjGhBaJTQSWMeta?.path ?? "/zh/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___tr",
    path: indexjGhBaJTQSWMeta?.path ?? "/tr/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexjGhBaJTQSWMeta?.name ?? "career___hy",
    path: indexjGhBaJTQSWMeta?.path ?? "/hy/career",
    meta: indexjGhBaJTQSWMeta || {},
    alias: indexjGhBaJTQSWMeta?.alias || [],
    redirect: indexjGhBaJTQSWMeta?.redirect,
    component: () => import("/usr/app/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___en",
    path: young1kPInvhIsjMeta?.path ?? "/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___ar",
    path: young1kPInvhIsjMeta?.path ?? "/ar/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___mn",
    path: young1kPInvhIsjMeta?.path ?? "/mn/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___ru",
    path: young1kPInvhIsjMeta?.path ?? "/ru/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___kk",
    path: young1kPInvhIsjMeta?.path ?? "/kk/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___zh",
    path: young1kPInvhIsjMeta?.path ?? "/zh/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___tr",
    path: young1kPInvhIsjMeta?.path ?? "/tr/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: young1kPInvhIsjMeta?.name ?? "career-young___hy",
    path: young1kPInvhIsjMeta?.path ?? "/hy/career/young",
    meta: young1kPInvhIsjMeta || {},
    alias: young1kPInvhIsjMeta?.alias || [],
    redirect: young1kPInvhIsjMeta?.redirect,
    component: () => import("/usr/app/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___en",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___ar",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/ar/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___mn",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/mn/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___ru",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/ru/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___kk",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/kk/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___zh",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/zh/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___tr",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/tr/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration7ck9Buq3X3Meta?.name ?? "collaboration___hy",
    path: collaboration7ck9Buq3X3Meta?.path ?? "/hy/collaboration",
    meta: collaboration7ck9Buq3X3Meta || {},
    alias: collaboration7ck9Buq3X3Meta?.alias || [],
    redirect: collaboration7ck9Buq3X3Meta?.redirect,
    component: () => import("/usr/app/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___en",
    path: contacts1ADgdaWO1AMeta?.path ?? "/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___ar",
    path: contacts1ADgdaWO1AMeta?.path ?? "/ar/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___mn",
    path: contacts1ADgdaWO1AMeta?.path ?? "/mn/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___ru",
    path: contacts1ADgdaWO1AMeta?.path ?? "/ru/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___kk",
    path: contacts1ADgdaWO1AMeta?.path ?? "/kk/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___zh",
    path: contacts1ADgdaWO1AMeta?.path ?? "/zh/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___tr",
    path: contacts1ADgdaWO1AMeta?.path ?? "/tr/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contacts1ADgdaWO1AMeta?.name ?? "contacts___hy",
    path: contacts1ADgdaWO1AMeta?.path ?? "/hy/contacts",
    meta: contacts1ADgdaWO1AMeta || {},
    alias: contacts1ADgdaWO1AMeta?.alias || [],
    redirect: contacts1ADgdaWO1AMeta?.redirect,
    component: () => import("/usr/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___en",
    path: cookiespT80wZvUI0Meta?.path ?? "/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___ar",
    path: cookiespT80wZvUI0Meta?.path ?? "/ar/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___mn",
    path: cookiespT80wZvUI0Meta?.path ?? "/mn/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___ru",
    path: cookiespT80wZvUI0Meta?.path ?? "/ru/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___kk",
    path: cookiespT80wZvUI0Meta?.path ?? "/kk/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___zh",
    path: cookiespT80wZvUI0Meta?.path ?? "/zh/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___tr",
    path: cookiespT80wZvUI0Meta?.path ?? "/tr/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiespT80wZvUI0Meta?.name ?? "cookies___hy",
    path: cookiespT80wZvUI0Meta?.path ?? "/hy/cookies",
    meta: cookiespT80wZvUI0Meta || {},
    alias: cookiespT80wZvUI0Meta?.alias || [],
    redirect: cookiespT80wZvUI0Meta?.redirect,
    component: () => import("/usr/app/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___en",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___ar",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/ar/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___mn",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/mn/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___ru",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/ru/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___kk",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/kk/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___zh",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/zh/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___tr",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/tr/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerP3EUrMXKvgMeta?.name ?? "disclaimer___hy",
    path: disclaimerP3EUrMXKvgMeta?.path ?? "/hy/disclaimer",
    meta: disclaimerP3EUrMXKvgMeta || {},
    alias: disclaimerP3EUrMXKvgMeta?.alias || [],
    redirect: disclaimerP3EUrMXKvgMeta?.redirect,
    component: () => import("/usr/app/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___en",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___ar",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/ar/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___mn",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/mn/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___ru",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/ru/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___kk",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/kk/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___zh",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/zh/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___tr",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/tr/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H7w7jcID4FMeta?.name ?? "employees-slug___hy",
    path: _91slug_93H7w7jcID4FMeta?.path ?? "/hy/employees/:slug()",
    meta: _91slug_93H7w7jcID4FMeta || {},
    alias: _91slug_93H7w7jcID4FMeta?.alias || [],
    redirect: _91slug_93H7w7jcID4FMeta?.redirect,
    component: () => import("/usr/app/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___en",
    path: index7cY0PKpVe3Meta?.path ?? "/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___ar",
    path: index7cY0PKpVe3Meta?.path ?? "/ar/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___mn",
    path: index7cY0PKpVe3Meta?.path ?? "/mn/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___ru",
    path: index7cY0PKpVe3Meta?.path ?? "/ru/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___kk",
    path: index7cY0PKpVe3Meta?.path ?? "/kk/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___zh",
    path: index7cY0PKpVe3Meta?.path ?? "/zh/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___tr",
    path: index7cY0PKpVe3Meta?.path ?? "/tr/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index7cY0PKpVe3Meta?.name ?? "employees___hy",
    path: index7cY0PKpVe3Meta?.path ?? "/hy/employees",
    meta: index7cY0PKpVe3Meta || {},
    alias: index7cY0PKpVe3Meta?.alias || [],
    redirect: index7cY0PKpVe3Meta?.redirect,
    component: () => import("/usr/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___en",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___ar",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/ar/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___mn",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/mn/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___ru",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/ru/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___kk",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/kk/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___zh",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/zh/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___tr",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/tr/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93a8olTFVlozMeta?.name ?? "events-eventSlug___hy",
    path: _91eventSlug_93a8olTFVlozMeta?.path ?? "/hy/events/:eventSlug()",
    meta: _91eventSlug_93a8olTFVlozMeta || {},
    alias: _91eventSlug_93a8olTFVlozMeta?.alias || [],
    redirect: _91eventSlug_93a8olTFVlozMeta?.redirect,
    component: () => import("/usr/app/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___en",
    path: indexfzSMqasWUtMeta?.path ?? "/",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___ar",
    path: indexfzSMqasWUtMeta?.path ?? "/ar",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___mn",
    path: indexfzSMqasWUtMeta?.path ?? "/mn",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___ru",
    path: indexfzSMqasWUtMeta?.path ?? "/ru",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___kk",
    path: indexfzSMqasWUtMeta?.path ?? "/kk",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___zh",
    path: indexfzSMqasWUtMeta?.path ?? "/zh",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___tr",
    path: indexfzSMqasWUtMeta?.path ?? "/tr",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index___hy",
    path: indexfzSMqasWUtMeta?.path ?? "/hy",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___en",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___ar",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/ar/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___mn",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/mn/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___ru",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/ru/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___kk",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/kk/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___zh",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/zh/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___tr",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/tr/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZPEGLu0igIMeta?.name ?? "industries-slug___hy",
    path: _91slug_93ZPEGLu0igIMeta?.path ?? "/hy/industries/:slug()",
    meta: _91slug_93ZPEGLu0igIMeta || {},
    alias: _91slug_93ZPEGLu0igIMeta?.alias || [],
    redirect: _91slug_93ZPEGLu0igIMeta?.redirect,
    component: () => import("/usr/app/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___en",
    path: index7vmr8VDS66Meta?.path ?? "/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___ar",
    path: index7vmr8VDS66Meta?.path ?? "/ar/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___mn",
    path: index7vmr8VDS66Meta?.path ?? "/mn/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___ru",
    path: index7vmr8VDS66Meta?.path ?? "/ru/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___kk",
    path: index7vmr8VDS66Meta?.path ?? "/kk/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___zh",
    path: index7vmr8VDS66Meta?.path ?? "/zh/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___tr",
    path: index7vmr8VDS66Meta?.path ?? "/tr/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: index7vmr8VDS66Meta?.name ?? "industries___hy",
    path: index7vmr8VDS66Meta?.path ?? "/hy/industries",
    meta: index7vmr8VDS66Meta || {},
    alias: index7vmr8VDS66Meta?.alias || [],
    redirect: index7vmr8VDS66Meta?.redirect,
    component: () => import("/usr/app/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___en",
    path: indexomVB49jzMHMeta?.path ?? "/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___ar",
    path: indexomVB49jzMHMeta?.path ?? "/ar/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___mn",
    path: indexomVB49jzMHMeta?.path ?? "/mn/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___ru",
    path: indexomVB49jzMHMeta?.path ?? "/ru/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___kk",
    path: indexomVB49jzMHMeta?.path ?? "/kk/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___zh",
    path: indexomVB49jzMHMeta?.path ?? "/zh/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___tr",
    path: indexomVB49jzMHMeta?.path ?? "/tr/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexomVB49jzMHMeta?.name ?? "insights___hy",
    path: indexomVB49jzMHMeta?.path ?? "/hy/insights",
    meta: indexomVB49jzMHMeta || {},
    alias: indexomVB49jzMHMeta?.alias || [],
    redirect: indexomVB49jzMHMeta?.redirect,
    component: () => import("/usr/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___en",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___ar",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/ar/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___mn",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/mn/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___ru",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/ru/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___kk",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/kk/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___zh",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/zh/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___tr",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/tr/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93tVOwVxHbBhMeta?.name ?? "news-newsSlug___hy",
    path: _91newsSlug_93tVOwVxHbBhMeta?.path ?? "/hy/news/:newsSlug()",
    meta: _91newsSlug_93tVOwVxHbBhMeta || {},
    alias: _91newsSlug_93tVOwVxHbBhMeta?.alias || [],
    redirect: _91newsSlug_93tVOwVxHbBhMeta?.redirect,
    component: () => import("/usr/app/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___en",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___ar",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/ar/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___mn",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/mn/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___ru",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/ru/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___kk",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/kk/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___zh",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/zh/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___tr",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/tr/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93RA1BVhlwL0Meta?.name ?? "newsletters-newsletterSlug___hy",
    path: _91newsletterSlug_93RA1BVhlwL0Meta?.path ?? "/hy/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93RA1BVhlwL0Meta || {},
    alias: _91newsletterSlug_93RA1BVhlwL0Meta?.alias || [],
    redirect: _91newsletterSlug_93RA1BVhlwL0Meta?.redirect,
    component: () => import("/usr/app/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___en",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___ar",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/ar/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___mn",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/mn/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___ru",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/ru/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___kk",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/kk/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___zh",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/zh/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___tr",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/tr/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businessPqpeeOcZbvMeta?.name ?? "page-doing-business___hy",
    path: doing_45businessPqpeeOcZbvMeta?.path ?? "/hy/page/doing-business",
    meta: doing_45businessPqpeeOcZbvMeta || {},
    alias: doing_45businessPqpeeOcZbvMeta?.alias || [],
    redirect: doing_45businessPqpeeOcZbvMeta?.redirect,
    component: () => import("/usr/app/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___ar",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/ar/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___mn",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/mn/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___ru",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/ru/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___kk",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/kk/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___zh",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/zh/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___tr",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/tr/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyTMnQ9cnLNrMeta?.name ?? "privacy-policy___hy",
    path: privacy_45policyTMnQ9cnLNrMeta?.path ?? "/hy/privacy-policy",
    meta: privacy_45policyTMnQ9cnLNrMeta || {},
    alias: privacy_45policyTMnQ9cnLNrMeta?.alias || [],
    redirect: privacy_45policyTMnQ9cnLNrMeta?.redirect,
    component: () => import("/usr/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___en",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___ar",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/ar/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___mn",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/mn/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___ru",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/ru/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___kk",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/kk/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___zh",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/zh/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___tr",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/tr/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoGr6yo8z89GMeta?.name ?? "pro-bono___hy",
    path: pro_45bonoGr6yo8z89GMeta?.path ?? "/hy/pro-bono",
    meta: pro_45bonoGr6yo8z89GMeta || {},
    alias: pro_45bonoGr6yo8z89GMeta?.alias || [],
    redirect: pro_45bonoGr6yo8z89GMeta?.redirect,
    component: () => import("/usr/app/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___en",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___ar",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/ar/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___mn",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/mn/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___ru",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/ru/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___kk",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/kk/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___zh",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/zh/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___tr",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/tr/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93qMMZfazwGvMeta?.name ?? "publications-publicationSlug___hy",
    path: _91publicationSlug_93qMMZfazwGvMeta?.path ?? "/hy/publications/:publicationSlug()",
    meta: _91publicationSlug_93qMMZfazwGvMeta || {},
    alias: _91publicationSlug_93qMMZfazwGvMeta?.alias || [],
    redirect: _91publicationSlug_93qMMZfazwGvMeta?.redirect,
    component: () => import("/usr/app/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___en",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___ar",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/ar/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___mn",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/mn/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___ru",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/ru/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___kk",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/kk/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___zh",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/zh/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___tr",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/tr/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionnsYeaVLeOhMeta?.name ?? "recognition___hy",
    path: recognitionnsYeaVLeOhMeta?.path ?? "/hy/recognition",
    meta: recognitionnsYeaVLeOhMeta || {},
    alias: recognitionnsYeaVLeOhMeta?.alias || [],
    redirect: recognitionnsYeaVLeOhMeta?.redirect,
    component: () => import("/usr/app/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___en",
    path: index2AvZ8pWHkwMeta?.path ?? "/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___ar",
    path: index2AvZ8pWHkwMeta?.path ?? "/ar/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___mn",
    path: index2AvZ8pWHkwMeta?.path ?? "/mn/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___ru",
    path: index2AvZ8pWHkwMeta?.path ?? "/ru/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___kk",
    path: index2AvZ8pWHkwMeta?.path ?? "/kk/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___zh",
    path: index2AvZ8pWHkwMeta?.path ?? "/zh/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___tr",
    path: index2AvZ8pWHkwMeta?.path ?? "/tr/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: index2AvZ8pWHkwMeta?.name ?? "regions___hy",
    path: index2AvZ8pWHkwMeta?.path ?? "/hy/regions",
    meta: index2AvZ8pWHkwMeta || {},
    alias: index2AvZ8pWHkwMeta?.alias || [],
    redirect: index2AvZ8pWHkwMeta?.redirect,
    component: () => import("/usr/app/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___en",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___ar",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/ar/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___mn",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/mn/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___ru",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/ru/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___kk",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/kk/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___zh",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/zh/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___tr",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/tr/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapZ9MJNeKVKxMeta?.name ?? "sitemap___hy",
    path: sitemapZ9MJNeKVKxMeta?.path ?? "/hy/sitemap",
    meta: sitemapZ9MJNeKVKxMeta || {},
    alias: sitemapZ9MJNeKVKxMeta?.alias || [],
    redirect: sitemapZ9MJNeKVKxMeta?.redirect,
    component: () => import("/usr/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___en",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___ar",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/ar/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___mn",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/mn/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___ru",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/ru/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___kk",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/kk/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___zh",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/zh/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___tr",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/tr/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939tdDNlJ3bdMeta?.name ?? "specializations-slug___hy",
    path: _91slug_939tdDNlJ3bdMeta?.path ?? "/hy/specializations/:slug()",
    meta: _91slug_939tdDNlJ3bdMeta || {},
    alias: _91slug_939tdDNlJ3bdMeta?.alias || [],
    redirect: _91slug_939tdDNlJ3bdMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___en",
    path: indexPHCZ5AYvFAMeta?.path ?? "/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___ar",
    path: indexPHCZ5AYvFAMeta?.path ?? "/ar/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___mn",
    path: indexPHCZ5AYvFAMeta?.path ?? "/mn/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___ru",
    path: indexPHCZ5AYvFAMeta?.path ?? "/ru/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___kk",
    path: indexPHCZ5AYvFAMeta?.path ?? "/kk/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___zh",
    path: indexPHCZ5AYvFAMeta?.path ?? "/zh/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___tr",
    path: indexPHCZ5AYvFAMeta?.path ?? "/tr/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexPHCZ5AYvFAMeta?.name ?? "specializations___hy",
    path: indexPHCZ5AYvFAMeta?.path ?? "/hy/specializations",
    meta: indexPHCZ5AYvFAMeta || {},
    alias: indexPHCZ5AYvFAMeta?.alias || [],
    redirect: indexPHCZ5AYvFAMeta?.redirect,
    component: () => import("/usr/app/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubElTBndi2gDMeta?.name ?? undefined,
    path: component_45stubElTBndi2gDMeta?.path ?? "/sitemap.xml",
    meta: component_45stubElTBndi2gDMeta || {},
    alias: component_45stubElTBndi2gDMeta?.alias || [],
    redirect: component_45stubElTBndi2gDMeta?.redirect,
    component: component_45stubElTBndi2gD
  }
]