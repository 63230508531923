<script lang="ts" setup>
import type { NuxtError } from '#app'

const props = defineProps({
    error: Object as () => NuxtError
})

const handleError = (path: string) =>
    reloadNuxtApp({ path: path || '/' })

onMounted(() => {
    if (props.error) {
        document.addEventListener('click', e => {
            const target = e.target as HTMLElement

            if (target.tagName === 'A') {
                e.preventDefault()
                const href = target.getAttribute('href')
                handleError(href || '/')
            } else {
                handleError('/')
            }
        })
    }
})
</script>

<template>
    <NuxtLayout>
        <div class="body">
            <h1 class="title">{{ error.statusCode }}</h1>
            {{ error }}
            <p class="message">This page does not exist</p>
            <button
                class="home-link"
                name="reset"
                @click.stop="handleError('/')"
            >
                Home Page
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.8334 15.625C10.7513 15.6254 10.67 15.6094 10.5941 15.5779C10.5183 15.5464 10.4495 15.5001 10.3918 15.4417C10.2747 15.3245 10.209 15.1657 10.209 15C10.209 14.8344 10.2747 14.6756 10.3918 14.5584L14.9501 10L10.3918 5.4417C10.2814 5.32322 10.2213 5.16652 10.2241 5.0046C10.227 4.84268 10.2926 4.68819 10.4071 4.57368C10.5216 4.45917 10.6761 4.39358 10.838 4.39072C10.9999 4.38787 11.1566 4.44797 11.2751 4.55837L16.2751 9.55837C16.3921 9.67556 16.4579 9.83441 16.4579 10C16.4579 10.1657 16.3921 10.3245 16.2751 10.4417L11.2751 15.4417C11.2174 15.5001 11.1486 15.5464 11.0728 15.5779C10.9969 15.6094 10.9156 15.6254 10.8334 15.625Z"
                        fill="white"
                    />
                    <path
                        d="M15.8337 10.625H4.16699C4.00123 10.625 3.84226 10.5592 3.72505 10.4419C3.60784 10.3247 3.54199 10.1658 3.54199 10C3.54199 9.83424 3.60784 9.67527 3.72505 9.55806C3.84226 9.44085 4.00123 9.375 4.16699 9.375H15.8337C15.9994 9.375 16.1584 9.44085 16.2756 9.55806C16.3928 9.67527 16.4587 9.83424 16.4587 10C16.4587 10.1658 16.3928 10.3247 16.2756 10.4419C16.1584 10.5592 15.9994 10.625 15.8337 10.625Z"
                        fill="white"
                    />
                </svg>
            </button>

            <div v-if="error && isDevMode" class="error">
                <h2 style="color: #fff">
                    Status {{ error.statusCode }}
                </h2>
                <pre>
                    <p style="color: #fff">Message {{ error.message }}</p>
                </pre>

                <pre style="color: #fff" v-html="error.stack"></pre>
            </div>
        </div>
    </NuxtLayout>
</template>

<style lang="scss" scoped>
$primarycolor: #34559a;
$standout: #fff;
$standouthover: #fff;

.body {
    background: url('@/assets/images/bg-404.png') no-repeat;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    height: 550px;
    color: #fff;
    text-align: left;
}

.title {
    font-size: 60px;
}

.message {
    margin-bottom: 16px;
    font-size: 32px;
}

.home-link {
    display: inline-flex;
    background-color: #050f38;
    padding: 16px;
    color: #fff;
    gap: 4px;
    font-size: 35px;
    align-items: center;

    svg {
        width: 41px;
        height: 41px;
    }
}

.error {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 60%;
    margin-top: 40px;
    margin-bottom: 40px;

    svg {
        display: block;
        width: 100%;
        height: auto;
        fill: $standout;
    }
}
</style>
