
// @ts-nocheck
import locale__usr_app_i18n_en from "../i18n/en";
import locale__usr_app_i18n_ar from "../i18n/ar";
import locale__usr_app_i18n_mn from "../i18n/mn";
import locale__usr_app_i18n_ru from "../i18n/ru";
import locale__usr_app_i18n_kk from "../i18n/kk";
import locale__usr_app_i18n_zh from "../i18n/zh";
import locale__usr_app_i18n_tr from "../i18n/tr";
import locale__usr_app_i18n_hy from "../i18n/hy";


export const localeCodes =  [
  "en",
  "ar",
  "mn",
  "ru",
  "kk",
  "zh",
  "tr",
  "hy"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/en", load: () => Promise.resolve(locale__usr_app_i18n_en), cache: true }],
  "ar": [{ key: "../i18n/ar", load: () => Promise.resolve(locale__usr_app_i18n_ar), cache: true }],
  "mn": [{ key: "../i18n/mn", load: () => Promise.resolve(locale__usr_app_i18n_mn), cache: true }],
  "ru": [{ key: "../i18n/ru", load: () => Promise.resolve(locale__usr_app_i18n_ru), cache: true }],
  "kk": [{ key: "../i18n/kk", load: () => Promise.resolve(locale__usr_app_i18n_kk), cache: true }],
  "zh": [{ key: "../i18n/zh", load: () => Promise.resolve(locale__usr_app_i18n_zh), cache: true }],
  "tr": [{ key: "../i18n/tr", load: () => Promise.resolve(locale__usr_app_i18n_tr), cache: true }],
  "hy": [{ key: "../i18n/hy", load: () => Promise.resolve(locale__usr_app_i18n_hy), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "name": "English",
      "code": "en",
      "iso": "en",
      "dir": "ltr",
      "files": [
        "i18n/en"
      ]
    },
    {
      "name": "Arabic",
      "dir": "rtl",
      "iso": "ar",
      "code": "ar",
      "files": [
        "i18n/ar"
      ]
    },
    {
      "name": "Mongol",
      "dir": "ltr",
      "iso": "mn",
      "code": "mn",
      "files": [
        "i18n/mn"
      ]
    },
    {
      "name": "Russian",
      "code": "ru",
      "iso": "ru",
      "dir": "ltr",
      "files": [
        "i18n/ru"
      ]
    },
    {
      "name": "Kazakh",
      "code": "kk",
      "iso": "kk",
      "dir": "ltr",
      "files": [
        "i18n/kk"
      ]
    },
    {
      "name": "China",
      "code": "zh",
      "iso": "zh",
      "dir": "ltr",
      "files": [
        "i18n/zh"
      ]
    },
    {
      "name": "Turkish",
      "code": "tr",
      "iso": "tr",
      "dir": "ltr",
      "files": [
        "i18n/tr"
      ]
    },
    {
      "name": "Armenian",
      "code": "hy",
      "iso": "hy",
      "dir": "ltr",
      "files": [
        "i18n/hy"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "i18n/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "fallbackLocale": "en",
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "English",
    "code": "en",
    "iso": "en",
    "dir": "ltr",
    "files": [
      {
        "path": "i18n/en"
      }
    ]
  },
  {
    "name": "Arabic",
    "dir": "rtl",
    "iso": "ar",
    "code": "ar",
    "files": [
      {
        "path": "i18n/ar"
      }
    ]
  },
  {
    "name": "Mongol",
    "dir": "ltr",
    "iso": "mn",
    "code": "mn",
    "files": [
      {
        "path": "i18n/mn"
      }
    ]
  },
  {
    "name": "Russian",
    "code": "ru",
    "iso": "ru",
    "dir": "ltr",
    "files": [
      {
        "path": "i18n/ru"
      }
    ]
  },
  {
    "name": "Kazakh",
    "code": "kk",
    "iso": "kk",
    "dir": "ltr",
    "files": [
      {
        "path": "i18n/kk"
      }
    ]
  },
  {
    "name": "China",
    "code": "zh",
    "iso": "zh",
    "dir": "ltr",
    "files": [
      {
        "path": "i18n/zh"
      }
    ]
  },
  {
    "name": "Turkish",
    "code": "tr",
    "iso": "tr",
    "dir": "ltr",
    "files": [
      {
        "path": "i18n/tr"
      }
    ]
  },
  {
    "name": "Armenian",
    "code": "hy",
    "iso": "hy",
    "dir": "ltr",
    "files": [
      {
        "path": "i18n/hy"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
