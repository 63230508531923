import unhead_KgADcZ0jPj from "/usr/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/usr/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/usr/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/usr/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/usr/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handling_nfXsHBWcke from "/usr/app/plugins/error-handling.ts";
import maska_UHaKf2z1iQ from "/usr/app/plugins/maska.ts";
import recaptcha_85gNSCNFUU from "/usr/app/plugins/recaptcha.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  error_handling_nfXsHBWcke,
  maska_UHaKf2z1iQ,
  recaptcha_85gNSCNFUU
]